@import "assets/css/variables.scss";

.guest-layout {
  min-height: 100vh;
  background-color: $blue;
  background-size: cover;
  background-position: center;
  padding: 60px 10px 0;
  position: relative;
  .welcome-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 1;
  }

  @media (max-width: 991px) {
    flex-direction: column;
    padding-top: 100px;
    margin-bottom: -80px;
  }

  @media (max-width: 475px) {
    padding-top: 70px;
    .card-body {
      padding: 30px 15px !important;
    }
  }

  .page-header {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 2;
    background: $white;
    padding: 10px 30px;

    @media (max-width: 475px) {
      padding: 10px 15px;
    }

    .header-item {
      font-size: 26px;
      color: $gray;
      line-height: 0;

      @media (max-width: 475px) {
        font-size: 18px;

        a.btn {
          font-size: 10px;

          &:nth-of-type(1) {
            margin-right: 5px !important;
          }
        }
      }

      .page-logo {
        width: 40px;
        margin-right: 20px;

        @media (max-width: 475px) {
          width: 30px;
          margin-right: 10px;
        }

        img {
          width: 100%;
        }
      }
    }
  }

  .welcome-item {
    position: relative;
    z-index: 3;
    &:nth-of-type(2) {
      flex: 0 0 360px;
      max-width: 360px;
      margin-left: 50px;

      @media (max-width: 991px) {
        margin: 0;
        max-width: 400px;
        width: 100%;
        flex: 0 0 100%;
      }

      .card {
        background: $white;
        .ant-form-item,
        .form-group {
          margin-bottom: 0;
          .ant-input-password {
            background: $white;
            border: 1px solid #ced4da;
          }
        }

        label {
          color: #6c757d;
        }

        input {
          color: #6c757d;
          border: 1px solid #ced4da;
          background: $white;

          &::placeholder {
            color: #888d91;
          }
        }

        .input-group-text {
          background: #f7f7f7;
          border: 1px solid #ced4da;
        }

        .remember-password {
          &.dark {
            a {
              color: #6c757d;

              &:hover {
                color: $blue;
              }
            }
          }
        }
      }
    }

    h1 {
      font-size: 42px;
      color: $white;
      margin-bottom: 30px;
      line-height: 1.3;

      @media (max-width: 1080px) {
        font-size: 36px;
      }

      @media (max-width: 991px) {
        text-align: center;
      }

      @media (max-width: 475px) {
        font-size: 24px;
        margin-bottom: 5px;
      }
    }

    ul {
      list-style: none;
      padding: 0;

      li {
        padding: 10px 0;
        font-size: 24px;
        color: $white;

        img {
          width: 20px;
          height: 20px;
          margin-right: 10px;
        }

        @media (max-width: 1080px) {
          font-size: 18px;
        }

        @media (max-width: 991px) {
          text-align: center;
        }

        @media (max-width: 475px) {
          font-size: 14px;

          img {
            width: 10px;
            height: 10px;
            margin-right: 10px;
          }
        }
      }
    }
  }
}
