.tong-quan-page {
  #total-revenue {
    max-width: 405px;
    margin: 0 auto;
  }
  .card-box {
    height: 100%;
    margin: 0;
    .table-action-icon {
      padding: 0 8px;
    }
  }
  @media (max-width: 1366px) {
    .tong-quan-items {
      .col-md-6 {
        margin-bottom: 20px;
      }
    }
    .tong-quan-tables {
      .col-xl-6 {
        margin-bottom: 20px;
      }
    }
  }
}
