.add-facebook-tracking-modal {
  .modal-image {
    width: 200px;
    margin: 0 auto;
    img {
      width: 100%;
    }
  }
  .modal-form {
    .ant-row {
      margin-bottom: 0;
    }
    .form-group {
      margin-bottom: 0;
    }
  }
  .btn-step-group {
    .ant-btn {
      width: auto;
      margin: 0 5px;
    }
  }
  .script-code {
    min-height: 100px;
    padding-right: 100px;
    height: auto;
  }
  .copy-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    width: auto;
    img {
      width: 12px;
      margin-right: 5px;
    }
  }
}
