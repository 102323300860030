@import "assets/css/variables.scss";

.data-tuoi-logo {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 22px;
  font-weight: 600;

  img {
    max-width: 30px;
    flex: 0 0 30px;
    height: auto;
    margin-right: 10px;
  }

  &.light {
    color: $white;
  }

  &.dark {
    color: $gray;
  }
}

.ant-picker {
  background-color: #3b4651;
  border: 1px solid #424e5a;
  font-size: 0.875rem;
  padding: 0.45rem 0.9rem;
  height: calc(1.5em + 0.9rem + 2px);

  .ant-picker-clear {
    background-color: #3b4651;
    transform: translate(-3px, -7px);
  }

  input {
    color: #94a0ad;

    &::placeholder {
      color: #586877;
    }
  }

  .anticon {
    path {
      fill: #586877;
    }
  }

  .anticon-swap-right {
    transform: translateY(-4px);
  }
}

.ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single)::before,
.ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single)::before,
.ant-picker-cell-in-view.ant-picker-cell-in-range::before {
  background: #535b6780;
}

.ant-picker-date-panel
  .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start
  .ant-picker-cell-inner::after,
.ant-picker-date-panel
  .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end
  .ant-picker-cell-inner::after {
  background: #727c8b80;
}

.ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover::before {
  background: #727c8b80 !important;
}

.ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single).ant-picker-cell-range-hover-end:before,
.ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single).ant-picker-cell-range-hover-start:before {
  background: #727c8b80 !important;
}

.ant-picker-cell:hover:not(.ant-picker-cell-in-view) .ant-picker-cell-inner,
.ant-picker-cell:hover:not(.ant-picker-cell-selected):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end):not(.ant-picker-cell-range-hover-start):not(.ant-picker-cell-range-hover-end)
  .ant-picker-cell-inner {
  background: #1890ff;
  color: #fff;
}

.ant-picker-dropdown {
  .ant-picker-panel-container {
    background: #3b4651;
    border: 1px solid #414d59;

    .ant-picker-panel {
      border-bottom: 1px solid #414d59;

      .ant-picker-month-btn,
      .ant-picker-year-btn,
      .ant-picker-content th,
      .ant-picker-content td.ant-picker-cell-in-view {
        color: #94a0ad;
      }

      .ant-picker-cell {
        &.ant-picker-cell-disabled {
          &::before {
            background: #535b6780;
          }
        }
      }

      button {
        .ant-picker-super-prev-icon,
        .ant-picker-prev-icon,
        .ant-picker-next-icon,
        .ant-picker-super-next-icon {
          &:after,
          &:before {
            border-color: #94a0ad;
          }
        }
      }
    }

    .ant-picker-header {
      border-bottom: 1px solid #414d59;
    }
  }

  .ant-picker-range-arrow {
    background: #3b4651;

    &:after {
      border-color: #3b4651 #3b4651 transparent transparent;
    }
  }
}

.form-control-password {
  width: 100%;
  height: calc(1.5em + 0.9rem + 2px);
  padding: 0.45rem 0.9rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  background-clip: padding-box;
  background-color: #3b4651;
  border: 1px solid #424e5a;
  border-radius: 0.2rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  &.light {
    border: 1px solid #ced4da;
    background: #fff;
  }

  svg {
    fill: #94a0ad;
  }

  input {
    color: #94a0ad;
    background: transparent;
    border: none !important;

    &::placeholder {
      color: #586877;
    }
  }
}

@keyframes autoFill {
  0%,
  100% {
    color: #94a0ad !important;
  }
}

.ant-form {
  label {
    color: #94a0ad;
  }

  &.light {
    input {
      &:-webkit-autofill,
      &:-webkit-autofill:hover,
      &:-webkit-autofill:focus,
      &:-webkit-autofill:active {
        box-shadow: 0 0 0px 1000px #fff inset;
        color: #94a0ad !important;
      }
    }
    .ant-form-item {
      &.ant-form-item-has-error {
        input,
        .ant-input-password {
          background: #fff !important;
          border-color: #ff4d4f !important;
        }
      }
    }
  }

  &.dark {
    input {
      &:-webkit-autofill,
      &:-webkit-autofill:hover,
      &:-webkit-autofill:focus,
      &:-webkit-autofill:active {
        box-shadow: 0 0 0px 1000px #3b4651 inset;
        color: #94a0ad !important;
      }
    }
    .ant-picker {
      background: #3b4651 !important;
    }
    .ant-form-item {
      &.ant-form-item-has-error {
        input,
        .ant-input-password {
          background: #3b4651 !important;
          border-color: #ff4d4f !important;
        }
      }
    }
  }
}

.custom-show-message {
  .ant-message-notice-content {
    background: $white;

    .ant-message-custom-content {
      display: flex;
      align-items: center;

      &.ant-message-error {
        color: #ff4d4f;
      }

      &.ant-message-success {
        color: #52c41a;
      }
    }
  }
}

.ant-btn {
  height: auto;

  &.ant-btn-loading {
    .ant-btn-loading-icon {
      transform: translateY(-3px);
    }
  }
}

.ant-dropdown {
  box-shadow: 0 0 35px 0 rgba(66, 72, 80, 0.15);
  border: 1px solid #414d59;
  background: #3b4651;
  padding: 5px;

  .dropdown-header {
    padding: 15px 15px;
  }

  .dropdown-header {
    font-size: 12px;
    color: #acbfd2;
  }

  .line {
    height: 1px;
    background: #94a0ad;
    margin: 5px 0;
  }

  .dropdown-item {
    cursor: pointer;
    font-size: 14px;
    padding: 15px 15px;
    color: #94a0ad;
    white-space: nowrap;
    line-height: 0;
    transition: 0.3s ease;
    min-width: 180px;
    &.disabled {
      cursor: not-allowed;
      opacity: 0.5;
    }

    &:hover {
      color: #fff;
    }

    img {
      flex: 0 0 15px;
      max-width: 15px;
      margin-right: 10px;
    }
  }
}

.ant-input-disabled {
  opacity: 0.6 !important;
  color: #80868c !important;
}

.ant-select {
  .ant-select-selector {
    border: 1px solid #424e5a !important;
    background: #3b4651 !important;
    padding: 0.15rem 8px !important;
    height: auto !important;

    .ant-select-selection-item {
      color: #94a0ad;
    }
  }

  .ant-select-arrow {
    svg {
      fill: #94a0ad;
    }
  }
}

.ant-select-dropdown {
  background: #3b4651;

  .ant-select-item {
    background: #3b4651;

    .ant-select-item-option-content {
      color: #94a0ad;
    }

    &:hover {
      background: lighten(#3b4651, 5%);
    }

    &.ant-select-item-option-selected {
      background: lighten(#3b4651, 15%);
    }
  }
}

.ant-modal-mask {
  backdrop-filter: blur(5px);
}

.ant-modal-wrap {
  .ant-modal {
    .ant-modal-content,
    .ant-modal-header {
      background: #36404a;
    }

    .ant-modal-close {
      svg {
        fill: #f7f7f7;
        transform: translateY(-2px);
      }
    }

    .ant-modal-header {
      border-bottom: 1px solid #414d59;

      .ant-modal-title {
        color: $white;
      }
    }
  }
}

.ant-input,
.ant-select,
.ant-picker {
  font-size: 15px;
}

.btn {
  font-size: 13px;
}

.ant-table,
p,
.tool-item {
  font-size: 15px;
}
.header-title {
  font-size: 17px;
}
.page-title-box .page-title {
  font-size: 21px;
}
.topnav .navbar-nav .nav-link {
  font-size: 16px;
}
.ant-empty-description {
  color: #94a0ad;
}

.ant-table-wrapper {
  .ant-table {
    background: transparent;

    .ant-table-thead > tr > th {
      background: transparent;
      border: none;
      border-top: 1px solid #424e5a;
      border-bottom: 2px solid #424e5a;
      color: #94a0ad;
      font-weight: bold;
    }

    .ant-table-tbody {
      .ant-empty {
        .ant-empty-description {
          color: #94a0ad;
        }
      }

      .ant-table-row {
        &:not(:last-of-type) {
          .ant-table-cell {
            border-bottom: 1px solid #424e5a;
          }
        }
      }

      .ant-table-cell {
        border: none;
        background: transparent;
        color: #94a0ad;
        font-weight: 500;

        &:hover {
          background: #3b4651;
        }
      }
    }
  }
}

.ant-pagination {
  .ant-pagination-prev,
  .ant-pagination-next {
    .ant-pagination-item-link {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      background: #3b4651;
      color: $white;
      border: 1px solid #424e5a;

      &:hover {
        background-color: #3283f6;
        border-color: #3283f6;
      }
    }
  }

  .ant-pagination-item {
    border-radius: 50%;
    background: #3b4651;
    color: $white;
    border: 1px solid #424e5a;

    &:hover,
    &.ant-pagination-item-active {
      background-color: #3283f6;
      border-color: #3283f6;
    }
  }

  .ant-pagination-item-ellipsis {
    color: #94a0ad !important;
  }

  .ant-pagination-item-link-icon {
    transform: translateY(-3px);
  }
}

.ant-steps {
  .ant-steps-item {
    &.ant-steps-item-process {
      .ant-steps-item-title {
        color: $white !important;
      }
    }

    &.ant-steps-item-finish {
      .ant-steps-item-tail {
        &::after {
          background: #44cf9c;
        }
      }
      .ant-steps-icon-dot {
        background: #44cf9c;
      }
      .ant-steps-item-title {
        color: #44cf9c !important;
      }
    }

    .ant-steps-icon-dot {
      background: #4a5561;
    }
    .ant-steps-item-title {
      color: #4a5561 !important;
    }

    .ant-steps-item-tail {
      &::after {
        background: #4a5561;
      }
    }
  }
}

.ant-checkbox-inner {
  background: transparent;
  border-color: #424e5a;
}

.min-height-100vh {
  min-height: 100vh;
}

.navbar-custom {
  padding: 0 15px !important;
  z-index: 100;
  @media (max-width: 991px) {
    padding: 0 25px !important;
  }
  @media (max-width: 768px) {
    padding: 0 15px !important;
  }
}
.topnav,
.content-page,
.footer {
  padding-left: 30px !important;
  padding-right: 30px !important;
}

.footer {
  bottom: unset;
}

//.content-page {
//  padding: 55px 30px 85px 30px !important;
//  @media (max-width: 768px) {
//    padding: 10px 15px 85px 15px !important;
//  }
//}

.topnav {
  height: 55px !important;
  .collapse {
    display: block !important;
    .navbar-nav {
      flex-direction: row !important;
    }
  }
}

.table-link {
  color: #94a0ad;
  &:hover {
    color: $blue;
  }
}

@media (max-width: 575px) {
  .account-pages {
    .card-body {
      padding: 30px 15px !important;
    }
  }
}

.width-125-px {
  width: 125px !important;
  white-space: nowrap;
}

.width-150-px {
  width: 150px !important;
}

@media (max-width: 1200px) {
  .width-150-px-in-1200-column {
    width: 150px !important;
    white-space: nowrap;
  }
}

@media (max-width: 1200px) {
  .width-125-px-in-1200-column {
    width: 125px !important;
    white-space: nowrap;
  }
}

@media (max-width: 768px) {
  .width-125-px-in-768-column {
    width: 125px !important;
  }
}

@media (max-width: 991.98px) {
  .content-page {
    margin-left: 0!important;
    padding: 0 10px !important;
  }

  .container, .container-fluid, .container-sm, .container-md, .container-lg, .container-xl {
    width: 100%;
    margin-right: auto;
    margin-left: auto;
  }
}
