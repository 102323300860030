.callio-call-history-page {
  .ant-table-content {
    overflow: auto;
  }
  .pagesize-select {
    margin-right: 25px;
    white-space: nowrap;

    .ant-select-selector {
      width: 70px;
    }
    .ant-select {
      margin: 0 10px;
    }
  }

  .table-panigate {
    flex-wrap: wrap;
    .dataTables_info,
    .tool-item.select,
    .table-pagination {
      margin-bottom: 10px;
    }
    .show-panigate {
      flex-wrap: wrap;
    }
    .ant-pagination {
      display: flex;

      @media (max-width: 475px) {
        flex-wrap: wrap;
      }
    }
  }
  .table-tools {
    flex-wrap: wrap;
    .tool-item.filter {
      flex-wrap: wrap;
    }
    .tool-item.search {
      margin-bottom: 10px;
    }
    .filter {
      .search {
        margin-right: 10px;
      }
    }
    .search-method-select {
      .ant-select-selector {
        min-width: 100px;
      }
    }
    .ant-select {
      margin: 0 10px;
    }
    .form-control {
      margin-left: 10px;
    }
  }

  .ant-progress-text {
    color: #94a0ad !important;
    font-weight: 500 !important;
    font-size: 14px !important;
  }

  audio {
    height: 20px;
  }
}
