.left-sidebar-page {
  .left-side-menu {
    background-color: #37424c;
    box-shadow: none;
  }

  .nav-link {
    transition: 0.3s ease;
    svg {
      width: 15px;
      height: 15px;
      fill: #919eab;
      margin-right: 10px;
      transition: 0.3s ease;
    }
    &:hover,
    &.active {
      color: #4d97ff !important;
      svg {
        fill: #4d97ff;
      }
    }
  }

}
