.ant-select {
  .ant-select-selector {
    .ant-select-selection-item {
      color: #94a0ad;
    }

    .ant-select-selection-search {
      input {
        color: #94a0ad;
      }
    }

    .ant-select-selection-placeholder {
      color: #94a0ad;
    }
  }
}
