@import "assets/css/variables.scss";

.get-started-page {
  max-width: 800px;
  margin: 40px auto;
  padding: 30px 15px 30px 15px;
  border-radius: 0.25rem;
  background: #36404a;
  .ant-steps {
    .ant-steps-item {
      &.ant-steps-item-process {
        .ant-steps-item-icon {
          background: #3283f6;
          border: 1px solid #3283f6;
          .ant-steps-icon {
            color: $white;
          }
        }
      }
      &.ant-steps-item-finish {
        .ant-steps-item-content {
          .ant-steps-item-title {
            &:after {
              background: #44cf9c;
            }
          }
        }
        .ant-steps-item-icon {
          background: #44cf9c;
          border: 1px solid #44cf9c;
          .ant-steps-icon {
            svg {
              fill: $white;
              transform: translateY(-3px);
            }
          }
        }
      }
      .ant-steps-item-content {
        .ant-steps-item-title {
          &:after {
            background: #4a5561;
          }
        }
      }
      .ant-steps-item-icon {
        background: #4a5561;
        border: 1px solid #424e5a;
        .ant-steps-icon {
          color: #424e5a;
        }
      }
    }
  }
  .modal-image {
    width: 300px;
    margin: 0 auto;
    img {
      width: 100%;
    }
  }
  .modal-form {
    .ant-row {
      margin-bottom: 0;
    }
    .form-group {
      margin-bottom: 0;
    }
  }
  .btn-step-group {
    .ant-btn {
      width: auto;
      margin: 0 5px;
    }
  }
  .script-code {
    height: auto;
    padding-right: 100px;
    margin-bottom: 20px;
  }
  .copy-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    width: auto;
    img {
      width: 12px;
      margin-right: 5px;
    }
  }
}
