.them-user-modal {
  .modal-image {
    width: 200px;
    margin-bottom: 10px;
  }
  .form-group {
    margin-bottom: 0;
    .ant-row {
      margin-bottom: 0;
    }
  }
}
