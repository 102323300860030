.get-code-modal {
  .script-code {
    min-height: 100px;
    padding-right: 100px;
    height: auto;
  }
  .copy-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    width: auto;
    img {
      width: 12px;
      margin-right: 5px;
    }
  }
}
