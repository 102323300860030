@import "assets/css/variables.scss";

.them-tu-khoa-modal {
  .modal-image {
    width: 200px;
    margin: 0 auto;
    img {
      width: 100%;
    }
  }
  .modal-form {
    .ant-row {
      margin-bottom: 0;
    }
    .form-group {
      margin-bottom: 0;
    }
  }
  .btn-action-group {
    margin-top: 20px;
    .ant-btn {
      width: auto;
      margin: 0 5px;
    }
  }
  .single-keyword {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    button {
      margin-top: 0.25rem;
    }
  }
}
