.input-autocomplete {
  position: relative;
  .ant-select {
    position: absolute;
    width: 100%;
    height: 100%;
    margin: 0;
    left: 0;
    top: 0;
    opacity: 0;
    pointer-events: none;
  }
}
