@import "assets/css/variables.scss";

.account-page {
  min-height: 100vh;
  .tai-khoan-items {
    .col-lg-4,
    .col-lg-8 {
      margin-bottom: 20px;
    }
    .nav {
      @media (max-width: 600px) {
        display: flex;
        flex-direction: column;
        .nav-item {
          margin-bottom: 10px;
        }
      }
    }
  }
  .avatar-user {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    margin: 0 auto;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      transform: scale(1.2);
    }
  }
  .form-group,
  .ant-row {
    margin-bottom: 0;
  }
  .account-tab-item {
    .telegram-logo {
      margin-bottom: 10px;
    }
  }
  .nav-pills {
    .nav-link {
      cursor: pointer;
      transition: 0.3s ease;
      &:hover {
        color: #fff;
        background-color: #3283f6;
      }
    }
  }
  .btn-connect {
    background: #229ed9;
    color: $white;
    &:hover {
      background: lighten(#229ed9, 5%);
    }
  }
}
