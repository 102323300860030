@font-face {
  font-family: "Cerebri Sans,sans-serif";
  src: url("assets/fonts/cerebrisans-light.eot");
  src: local("Cerebri-sans Light"),
    url("assets/fonts/cerebrisans-light.woff") format("woff");
  font-weight: 300;
}

@font-face {
  font-family: "Cerebri Sans,sans-serif";
  src: url("assets/fonts/cerebrisans-regular.eot");
  src: local("Cerebri-sans Regular"),
    url("assets/fonts/cerebrisans-regular.woff") format("woff");
  font-weight: 400;
}

@font-face {
  font-family: "Cerebri Sans,sans-serif";
  src: url("assets/fonts/cerebrisans-medium.eot");
  src: local("Cerebri-sans Medium"),
    url("assets/fonts/cerebrisans-medium.woff") format("woff");
  font-weight: 500;
}

@font-face {
  font-family: "Cerebri Sans,sans-serif";
  src: url("assets/fonts/cerebrisans-semibold.eot");
  src: local("Cerebri-sans Semibold"),
    url("assets/fonts/cerebrisans-semibold.woff") format("woff");
  font-weight: 600;
}

@font-face {
  font-family: "Cerebri Sans,sans-serif";
  src: url("assets/fonts/cerebrisans-bold.eot");
  src: local("Cerebri-sans Bold"),
    url("assets/fonts/cerebrisans-bold.woff") format("woff");
  font-weight: 700;
}

@font-face {
  font-family: "footable";
  src: url("assets/fonts/footable.eot");
  src: url("assets/fonts/footable.eot?#iefix") format("embedded-opentype"),
    url("assets/fonts/footable.woff") format("woff"),
    url("assets/fonts/footable.ttf") format("truetype"),
    url("assets/fonts/footable.svg#footable") format("svg");
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  @font-face {
    font-family: "footable";
    src: url("assets/fonts/footable.svg#footable") format("svg");
    font-weight: normal;
    font-style: normal;
  }
}

@font-face {
  font-family: "summernote";
  font-style: normal;
  font-weight: normal;
  src: url("assets/fonts/summernote.eot");
  src: url("assets/fonts/summernote.eot?#iefix") format("embedded-opentype"),
    url("assets/fonts/summernote.woff?") format("woff"),
    url("assets/fonts/summernote.ttf?") format("truetype");
}

@font-face {
  font-family: "dropify";
  src: url("assets/fonts/dropify.eot");
  src: url("assets/fonts/dropify.eot#iefix") format("embedded-opentype"),
    url("assets/fonts/dropify.woff") format("woff"),
    url("assets/fonts/dropify.ttf") format("truetype"),
    url("assets/fonts/dropify.svg#dropify") format("svg");
  font-weight: normal;
  font-style: normal;
}
