.them-don-hang-modal {
  p {
    color: #94a0ad;
  }
  .modal-image {
    width: 300px;
  }

  .btn-expired-picker {
    margin-bottom: 3px;
  }

  .hide-datepicker {
    position: absolute;
    margin-left: -215px;
    z-index: -1;
  }

  .control-number-expired .form-control {
    display: unset !important;
  }
}
