@import "assets/css/variables.scss";

.header-page {
  .avatar-wrap {
    cursor: pointer;
    height: 70px;
    padding: 0 10px;
    transition: 0.3s ease;
    &.ant-dropdown-open,
    &:hover {
      background: rgba(255, 255, 255, 0.05);
    }
    .icon-arrow {
      width: 10px;
    }
    .name {
      margin: 0 10px;
      font-size: 16px;
    }
    .avatar {
      flex: 0 0 32px;
      width: 32px;
      height: 32px;
      border-radius: 50%;
      position: relative;
      display: flex;
      align-items: center;
      justify-items: center;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  .link-hotline {
    display: block !important;

    a {
      color: #d60f0f;
      font-size: 16px;
      font-weight: 900;
      text-shadow: -1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff,
        1px 1px 0 #fff;
    }
  }

  .link-hotline-sm {
    display: none !important;
  }

  @media (max-width: 475px) {
    .logo-box {
      img {
        max-width: 25px;
        flex: 0 0 25px;
      }
      .data-tuoi-logo {
        font-size: 18px;
      }
    }

    .link-hotline-sm {
      display: block !important;

      a {
        color: #d60f0f;
        font-weight: 900;
        text-shadow: -1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff,
          1px 1px 0 #fff;
      }
    }

    .link-hotline {
      display: none !important;
    }
  }
  .nav-link {
    transition: 0.3s ease;
    svg {
      width: 15px;
      height: 15px;
      fill: #919eab;
      margin-right: 10px;
      transition: 0.3s ease;
    }
    &:hover,
    &.active {
      color: #4d97ff !important;
      svg {
        fill: #4d97ff;
      }
    }
  }
  .dropdown-btn-menu {
    display: none;
    width: 25px;
    height: 25px;
    cursor: pointer;
    img {
      width: 100%;
    }
  }
  @media (max-width: 768px) {
    .dropdown-btn-menu {
      display: block;
    }
    .topnav {
      transition: 0.3s ease;
      overflow: hidden !important;
      max-height: 0 !important;
      height: auto !important;
      &.active {
        max-height: 100vh !important;
      }

      .collapse .navbar-nav {
        display: flex;
        flex-direction: column !important;
        .nav-link {
          padding: 15px 0;
        }
      }
    }
  }
}
