.contact-detail-page {
  .ant-table-content {
    overflow: auto;
  }
  .pagesize-select {
    margin-right: 25px;
    white-space: nowrap;

    .ant-select-selector {
      width: 70px;
    }
    .ant-select {
      margin: 0 10px;
    }
  }

  .table-panigate {
    flex-wrap: wrap;
    .dataTables_info,
    .tool-item.select,
    .table-pagination {
      margin-bottom: 10px;
    }
    .show-panigate {
      flex-wrap: wrap;
    }
    .ant-pagination {
      display: flex;

      @media (max-width: 475px) {
        flex-wrap: wrap;
      }
    }
  }
  .table-tools {
    flex-wrap: wrap;
    .tool-item.filter {
      flex-wrap: wrap;
    }
    .tool-item.search {
      margin-bottom: 10px;
    }
    .filter {
      .search {
        margin-right: 10px;
      }
    }
    .search-method-select {
      .ant-select-selector {
        min-width: 100px;
      }
    }
    .ant-select {
      margin: 0 10px;
    }
    .form-control {
      margin-left: 10px;
    }
  }

  .btn-icon-edit {
    padding: 5px 9px;
    border-radius: 20px;
    margin-right: 40px;
    color: white;
    &:hover {
      cursor: pointer;
      background-color: #707e8d;
    }
  }

  .btn-icon-delete {
    padding: 5px 9px;
    border-radius: 20px;
    &:hover {
      cursor: pointer;
      background-color: #707e8d;
    }
  }

  .ant-table-row:hover .action {
    display: block !important;
    right: 30px;
  }

  .action {
    display: none !important;
    position: absolute;
    right: -100px;
    top: 11px;
    margin-left: 30px;
  }
}
